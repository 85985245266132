'use strict';

angular.module('bringgApp').component('templatedTaskInvoice', {
	bindings: {
		htmlTemplate: '=',
		config: '=',
		printedCopies: '=',
		tasks: '='
	},
	controller: (
		$q,
		$compile,
		$element,
		$scope,
		$timeout,
		$rootScope,
		TaskInventoriesService,
		CompaniesService,
		TaskDetailsService
	) => {
		$scope.tasks = $scope.$ctrl.tasks;
		$scope.config = $scope.$ctrl.config;
		$scope.printedCopies = $scope.$ctrl.printedCopies;
		$scope.hasVehicle = function (task) {
			return task.vehicle_id !== null;
		};
		$scope.driverBelongsToCompany = function (task) {
			return task.user && task.user.company !== null;
		};
		$scope.has_1wp = function (task) {
			return task.way_points && task.way_points.length === 1;
		};
		$scope.has_2wp = function (task) {
			return task.way_points && task.way_points.length === 2;
		};
		$scope.isTagId = function (task, tagId) {
			return task.tag_id === tagId;
		};
		$scope.getTotalWeight = function (task) {
			var wayPoint = _.last(task.way_points);
			var taskInventories = _.filter(task.task_inventories, { way_point_id: wayPoint.id });
			// calculate for parent task inventory + or orphand inventories
			taskInventories = _.filter(taskInventories, TaskInventoriesService.isParentTaskInventory);
			var totalWeight = _.reduce(
				taskInventories,
				function (totalWeight, taskInventory) {
					if (_.isNull(taskInventory.weight)) {
						return totalWeight;
					} else {
						var taskInventoryWeight = taskInventory.original_quantity * taskInventory.weight;
						return totalWeight + taskInventoryWeight;
					}
				},
				0
			);
			return totalWeight.toLocaleString('es');
		};

		$scope.getWayPointNote = function (task, wayPointId) {
			var waypoint = _.find(task.way_points, { id: wayPointId });
			var note = waypoint ? waypoint.note : '';
			if (_.isEmpty(note)) {
				var notes = _.chain(task.task_notes)
					.filter({ type: 'TaskNote', way_point_id: wayPointId })
					.map('note')
					.value();

				return notes.join(', ');
			}
			return note;
		};

		$scope.loading = true;

		CompaniesService.getAll().then(function (result) {
			_.map($scope.tasks, TaskDetailsService.fetchFleetName);
			return $q
				.all(
					_.map($scope.tasks, function (task) {
						return TaskDetailsService.fetchTaskData(task, result.companies);
					})
				)
				.then(function () {
					$scope.loading = false;
					const compiledTemplate = $compile($scope.$ctrl.htmlTemplate)($scope);
					$element.html(compiledTemplate);
					if (!$rootScope.$$phase) {
						$rootScope.$apply();
					}
				});
		});
	}
});
