'use strict';

angular
	.module('bringgApp')
	.controller(
		'enforcePasswordUpdateController',
		function (
			$scope,
			$location,
			Authentication,
			vcRecaptchaService,
			$routeParams,
			RECAPTCHA_SITE_KEY,
			toastr,
			Employee
		) {
			$scope.email = $routeParams?.email;
			$scope.show_captcha = false;
			$scope.show_enforce_password_update_message = false;
			$scope.show_remind_me_later = Boolean(Authentication.getSdk());
			$scope.show_email_message = false;

			$scope.recaptcha = null;
			$scope.widgetId = null;
			$scope.model = {
				key: RECAPTCHA_SITE_KEY
			};

			$scope.setRecaptcha = function (recaptcha) {
				$scope.recaptcha = recaptcha;
			};
			$scope.setWidgetId = function (widgetId) {
				$scope.widgetId = widgetId;
				if ($scope.show_captcha) {
					vcRecaptchaService.reload($scope.widgetId);
				}
			};
			$scope.cbExpiration = function () {
				$scope.recaptcha = null;
			};

			$scope.remindMeLater = function () {
				$location.path('/login/').search({});
				Authentication.remindMeLater();
			};
			$scope.updatePasswordNow = function () {
				var params = {
					email: $scope.email,
					is_update_password: true,
					recaptcha: $scope.show_captcha ? vcRecaptchaService.getResponse($scope.widgetId) : ''
				};

				Employee.password_reminder(params, function (response) {
					if (response.success) {
						$scope.show_email_message = true;
						$scope.show_captcha = false;
					} else if (response.recaptcha_stoken) {
						$scope.stoken = response.recaptcha_stoken;
						$scope.show_captcha = true;
						if ($scope.widgetId !== null) {
							vcRecaptchaService.reload($scope.widgetId);
						}
					} else {
						$scope.show_captcha = false;
						toastr.error(response.message);
					}
				});
			};
		}
	);
