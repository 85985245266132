'use strict';

angular
	.module('bringgApp')
	.controller(
		'DispatchListController',
		function (
			$scope,
			$timeout,
			Tasks,
			TasksLoader,
			Teams,
			localStorageService,
			$window,
			MerchantConfigurations,
			Authentication,
			$rootScope,
			Employees,
			Task,
			toastr,
			OptimizeModalService,
			DispatchListService,
			$q,
			DispatcherPrintService,
			PrintTasksService,
			GridColumnsService,
			DEFAULT_RT_THROTTLE,
			Application,
			dialogSrv,
			ManagedAttributesService,
			TasksModalService,
			Tags,
			BroadcastTasksService,
			PRIVILEGES_TYPES,
			TranslationService,
			$injector,
			CustomSlickgridTooltip,
			TasksGrouping,
			VehiclesService,
			VehicleTypesService,
			UserType,
			PlannedRoutesService,
			SkillsService,
			RoutesPlannerService,
			RouteAssigner,
			CrossApplicationService,
			CROSS_APP_ACTIONS,
			PartialRunsSources,
			DriverConfigurations,
			GRID_DATA,
			AssignDriverValidations,
			ReactModals,
			OPTIMIZATION_TYPES,
			OptimizationTasksValidationService,
			optimizationTypesService,
			RunsService,
			CustomAttributesService,
			LinkedTasksService,
			ServiceAreasService,
			TasksData,
			SlickGridConfigService,
			PresetViewsService,
			ObjectUtilsService
		) {
			localStorageService.set('last_known_location', 'list');

			const GRID_COLUMNS_KEY = 'task_fields';

			$injector.get('StatusConfirmationService');
			$scope.hideBigMap = true;
			$scope.currentUser = Authentication.currentUser();
			$scope.tags = Tags.all();
			$scope.beta = Authentication.currentUser().beta;
			$scope.admin = Authentication.currentUser().admin;
			$scope.canCustomizeColumn = Authentication.currentUser().has_access(PRIVILEGES_TYPES.CUSTOMIZE_LIST_LAYOUT);
			$scope.canCancel = $scope.admin || Authentication.currentUser().has_access(PRIVILEGES_TYPES.CANCEL_TASK);
			$scope.canLockUnlock =
				$scope.admin || Authentication.currentUser().has_access(PRIVILEGES_TYPES.LOCK_UNLOCK_TASK);
			$scope.canEditTaskDetails =
				$scope.admin || Authentication.currentUser().has_access(PRIVILEGES_TYPES.EDIT_TASK_DETAILS);
			$scope.enablePlanningPhase = MerchantConfigurations.planning_phase_exists;
			$scope.allowMovingTasksToPlanningPhase = $scope.enablePlanningPhase;
			$scope.canMoveTaskToPlanningPhase =
				$scope.admin ||
				Authentication.currentUser().has_access(PRIVILEGES_TYPES.SEND_TASK_FROM_DISPATCH_TO_PLANNING);
			$scope.merchantConfiguration = MerchantConfigurations;
			$scope.sortFields = [];
			$scope.onlineDrivers = 0;
			$scope.onshiftDrivers = 0;
			$scope.filterObject = {};
			$scope.customizeListColumnsShown = false;
			$scope.gaPage = 'DispatchView';
			$scope.showEmptyTeams = MerchantConfigurations.dashboard_ui_configuration?.show_empty_teams ?? true;
			$scope.showEmptyDrivers = MerchantConfigurations.dashboard_ui_configuration?.show_empty_drivers ?? false;
			$scope.gridData = GRID_DATA.DISPATCH;
			$scope.isOptimizationButtonDisabled = false;
			$scope.dynamicCapacityMode = MerchantConfigurations.dynamic_capacity_values_mode;
			let _realtimeEtaEnabled;
			let realTimeAppConfiguration;
			let gridId = GRID_DATA.DISPATCH.id;

			$scope.enableRoutesPlanner =
				MerchantConfigurations.applications.some(Application.isRouteOptimizer2) &&
				Authentication.currentUser().has_access(PRIVILEGES_TYPES.ALLOW_USING_ROUTE_PLANNER_IN_DISPATCH) !==
					false;
			Authentication.featureFlags().then(featureFlags => {
				$scope.enableRouteOptimization =
					MerchantConfigurations.applications.some(Application.isRouteOptimizer) &&
					Authentication.currentUser().has_access(PRIVILEGES_TYPES.OPTIMIZE_ORDERS);
				$scope.enableOptimizeRoute = featureFlags.enable_optimize_route || false;
				$scope.hideNeedsToLeaveByForDrivers = featureFlags.hideNeedsToLeaveByForDrivers || false;
				$scope.hideBigMap = featureFlags.hide_big_map_access || false;
				$scope.moveTaskFromDispatchToPlanningPrivilegeEnabled =
					featureFlags.enable_move_task_from_dispatch_to_planning_privilege === true;

				$scope.shouldAllowRoutesPlanner = $scope.enableRoutesPlanner;
			});

			$scope.onlineFilter = Employees.filterIsOnline;
			//==============================================
			// DataGrid functionality START
			//==============================================
			/**
			 * after user saved new customized list columns
			 */
			$scope.onColumnsSave = function () {
				$scope.customizeListColumnsShown = false;
				$scope.initGridColumns();
				// setColumns visually does nothing without $scope.$broadcast('columns updated')
				// find out if it does anything at all or it is redundant
				$scope.grid.setColumns($scope.gridColumns);
				$scope._init().then(function () {
					// 'columns updated' seems to synchronise dispatch-controller with task-table.js
					// _initGrouping needs to be called strictly after 'columns updated'
					// otherwise some selection issues may appear in grouping mode
					$scope.$broadcast('columns updated', function () {
						return $scope._initGrouping();
					});
				});
			};

			/**
			 * init grid columns
			 */
			$scope.initGridColumns = function () {
				$scope.gridColumns = [checkboxSelector.getColumnDefinition()].concat(
					GridColumnsService.getConvertedColumnsFromMerchantConfigurations(null, GRID_COLUMNS_KEY)
				);
			};

			$scope.grid = {};
			var checkboxSelector = GridColumnsService.initGrid($scope);
			$scope.filterFunc = GridColumnsService.filterFunc;

			// cleaner
			$scope.$on('$destroy', function () {
				CrossApplicationService.off('FILTER_CHANGE', refreshFiltersOnFiltersChange);
				CrossApplicationService.off(CROSS_APP_ACTIONS.PRESET_VIEWS_UPDATED, $scope._init);

				subscriptions.forEach(function (item) {
					item();
				});
				subscriptions = [];
			});

			//==============================================
			// DataGrid functionality END
			//==============================================

			var refreshFiltersOnFiltersChange = function (event, removeMissingKeysFromPrevFilters) {
				if (removeMissingKeysFromPrevFilters) {
					ObjectUtilsService.deleteMissingKeys($scope.filterObject, event);
				}

				Object.assign($scope.filterObject, event);
				if ($scope.grid.refreshFilters) {
					$scope.grid.refreshFilters();
				}
			};

			CrossApplicationService.on('FILTER_CHANGE', refreshFiltersOnFiltersChange);

			//==============================================
			// Add Task START
			//==============================================
			$scope.addTask = function () {
				if ($scope.readonly) {
					return;
				}

				const selectedTeamId =
					$scope.filterObject &&
					$scope.filterObject.selectedTeams &&
					$scope.filterObject.selectedTeams.length === 1
						? $scope.filterObject.selectedTeams[0]
						: undefined;
				TasksModalService.addTask(undefined, undefined, selectedTeamId);
			};

			$scope.addTaskCSV = function () {
				if ($scope.readonly) {
					return;
				}

				TasksModalService.addTaskCSV();
			};
			//==============================================
			// Add Task END
			//==============================================
			$scope.handleBulkAssignEmployeeSelected = function (item) {
				const isAllowedAnswer = AssignDriverValidations.isAllowedToAssignSelectedTasks($scope.selectedItems);
				if (isAllowedAnswer.errMessage?.length > 0) {
					toastr.error(TranslationService.instant(isAllowedAnswer.errMessage));
				}

				if (!isAllowedAnswer.canAssign || isAllowedAnswer.assignableTasks?.length === 0) {
					return;
				}

				if (item && item.id) {
					Tasks.massAssign(item.id, isAllowedAnswer.assignableTasks)
						.then(function (result) {
							/*
							The first result is from SDK (V2)
							If there's error, the SDK catches exception and returns result = false
						*/
							if (result === true || result.success) {
								toastr.success(
									TranslationService.instant('DISPATCH_LIST.TOAST_ORDER_ASSIGNED') + ' ' + item.name
								);
							} else {
								toastr.error(TranslationService.instant('DISPATCH_LIST.TOAST_ORDER_ASSIGNED_FAILED'));
							}
						})
						.catch(function () {
							toastr.error(TranslationService.instant('DISPATCH_LIST.TOAST_ORDER_ASSIGNED_FAILED'));
						});
				} else if (_.isNull(item.id)) {
					Tasks.massUnassign(isAllowedAnswer.assignableTasks)
						.then(function (result) {
							/*
							The first result is from SDK (V2)
							If there's error, the SDK catches exception and returns result = false
						*/
							if (result === true || result.success) {
								toastr.success(TranslationService.instant('DISPATCH_LIST.TOAST_ORDER_UNASSIGNED'));
							} else {
								toastr.error(TranslationService.instant('DISPATCH_LIST.TOAST_ORDER_UNASSIGNED_FAILED'));
							}
						})
						.catch(function () {
							toastr.error(TranslationService.instant('DISPATCH_LIST.TOAST_ORDER_UNASSIGNED_FAILED'));
						});
				} else {
					toastr.error(TranslationService.instant('DISPATCH_LIST.TOAST_SELECT_DRIVER'));
				}
			};

			$scope.handleLockTaskClicked = function () {
				if (!$scope.selectedItems.length) {
					toastr.error(TranslationService.instant('DISPATCH_LIST.TOAST_NO_ORDERS'));
					return;
				}

				Task.lock({ task_ids: $scope.selectedItems.map(task => task.id) }, function (result) {
					if (!result.success) {
						toastr.error(TranslationService.instant('DISPATCH_LIST.TOAST_ORDER_LOCKED_FAILED'));
					} else {
						toastr.success(TranslationService.instant('DISPATCH_LIST.TOAST_ORDER_LOCKED'));
					}
				});
			};

			$scope.handleUnlockTaskClicked = function () {
				if (!$scope.selectedItems.length) {
					toastr.error(TranslationService.instant('DISPATCH_LIST.TOAST_NO_ORDERS'));
					return;
				}

				Task.unlock(
					{
						task_ids: $scope.selectedItems.map(task => task.id)
					},
					function (result) {
						if (!result.success) {
							toastr.error(TranslationService.instant('DISPATCH_LIST.TOAST_ORDER_UNLOCKED_FAILED'));
						} else {
							toastr.success(TranslationService.instant('DISPATCH_LIST.TOAST_ORDER_UNLOCKED'));
						}
					}
				);
			};

			$scope.moveTasksToPlanning = function (tasksIds) {
				Tasks.moveToPlanning({ task_ids: tasksIds })
					.then(function (result) {
						if (!result.success) {
							toastr.error(
								TranslationService.instant('DISPATCH_LIST.TOAST_ORDER_MOVE_TO_PLANNING_FAILED')
							);
						} else {
							toastr.success(TranslationService.instant('DISPATCH_LIST.TOAST_ORDER_MOVED_TO_PLANNING'));
						}
					})
					.catch(function (err) {
						console.error('cannot move order to planning : ', err);
						toastr.error(TranslationService.instant('DISPATCH_LIST.TOAST_ORDER_MOVE_TO_PLANNING_FAILED'));
					});
			};

			$scope.canMoveTasksFromDispatchToPlanning = function () {
				if ($scope.moveTaskFromDispatchToPlanningPrivilegeEnabled) {
					return $scope.canMoveTaskToPlanningPhase;
				}
				// default behavior before privilege was introduced
				return true;
			};

			$scope.handleMoveTasksToPlanningClicked = function () {
				if (!$scope.selectedItems.length) {
					toastr.error(TranslationService.instant('DISPATCH_LIST.TOAST_NO_ORDERS'));
					return;
				}

				if (!$scope.canMoveTasksFromDispatchToPlanning()) {
					toastr.error(TranslationService.instant('TASK.PERMISSION_DENIED'));
					return;
				}

				const partialRunIds = RunsService.getPartialRunIdsByTasks(
					$scope.selectedItems,
					TasksData.getOpenTasks()
				);
				if (Object.keys(partialRunIds).length) {
					ReactModals.openPartialRunsActionsModal({
						onResponse: $scope.moveTasksToPlanning,
						partialRunsSource: PartialRunsSources.MOVE_FROM_DISPATCH_TO_PLANNING,
						selectedTasksIds: $scope.selectedItems.map(task => task.id),
						partialRunIds: Object.keys(partialRunIds)
					});
				} else {
					$scope.moveTasksToPlanning($scope.selectedItems.map(task => task.id));
				}
			};
			//==============================================
			// Grouping START
			//==============================================
			$scope.groupByOptions = DispatchListService.groupByOptions;

			$scope.handleGroupingSelectionChange = function (item) {
				$scope.groupByValues = TasksGrouping.groupBy(
					item,
					{
						employees: $scope.employees,
						teams: $scope.teams,
						tasks: $scope.tasks,
						vehicleTypeByVehicleId: $scope.vehicleTypeByVehicleId,
						vehiclesById: $scope.vehiclesById,
						vehicleTypes: $scope.vehicleTypes,
						userTypes: $scope.userTypes,
						plannedRoutes: $scope.plannedRoutes,
						selectedRows: $scope.selectedRows,
						filterObject: $scope.filterObject
					},
					{
						showEmptyTeams: $scope.showEmptyTeams,
						showEmptyDrivers: $scope.showEmptyDrivers,
						isClusterModeEnabled: $scope.isClusterModeEnabled,
						doNotMultipleWeightByQuantity: $scope.doNotMultipleWeightByQuantity,
						dynamicCapacityMode: $scope.dynamicCapacityMode,
						realtimeEtaEnabled: _realtimeEtaEnabled,
						hideEtaToHomeForBusyDrivers: realTimeAppConfiguration?.data?.hideEtaToHomeForBusyDrivers,
						hideNeedsToLeaveByForDrivers: $scope.hideNeedsToLeaveByForDrivers,
						allowModifyPlannedRouteIdentifier: false,
						origin: 'dispatch',
						updateTasks: function (tasks) {
							$scope.tasks = tasks;
						}
					}
				);
				SlickGridConfigService.saveGrouping(gridId, 'taskGridGroupMode', item.value);
				$scope.groupingModel = { selectedOption: _.find($scope.groupByOptions, { value: item.value }) };
			};

			/**
			 * init grouping function
			 */
			$scope._initGrouping = function () {
				var groupingValue =
					SlickGridConfigService.getGrouping(gridId, 'taskGridGroupMode') || TasksGrouping.GROUP_TYPES.Team;

				$scope.groupingModel = {
					selectedOption:
						_.find($scope.groupByOptions, { value: groupingValue }) || _.first($scope.groupByOptions)
				};
				$scope.handleGroupingSelectionChange($scope.groupingModel.selectedOption);
			};
			//==============================================
			// Grouping END
			//==============================================
			$scope.handleCancelOrdersClicked = function () {
				if (!$scope.selectedItems.length) {
					toastr.error(TranslationService.instant('DISPATCH_LIST.TOAST_NO_ORDERS'));
					return;
				}

				ReactModals.openReasonToCancelTaskModal({
					tasks: $scope.selectedItems
				});
			};

			$scope.assignDriver = function (data) {
				angular.element($window).trigger('handleAssignRunToDriver', data);
			};

			$scope.canOpenPlannedRunsUserAssigner = RouteAssigner.canOpenPlannedRunsUserAssigner;
			$scope.isAllowedToBulkAssign = false;
			$scope.assignButtonTooltipText = '';

			function calculateBulkAssignStatus() {
				const isAllowedAnswer = AssignDriverValidations.isAllowedToBulkAssign($scope.selectedItems);
				$scope.isAllowedToBulkAssign = isAllowedAnswer.canAssign;

				$scope.assignButtonTooltipText =
					isAllowedAnswer.canAssign || !isAllowedAnswer.errMessage
						? ''
						: TranslationService.instant(isAllowedAnswer.errMessage);

				$timeout(() => angular.element('#driverSelect').trigger('click'));
			}

			function onSelectItem() {
				calculateBulkAssignStatus();
			}

			$scope.$watch('selectedItems.length', onSelectItem);

			$scope.canFinishOrders = function () {
				return $scope.currentUser.admin || $scope.currentUser.has_access('complete_task');
			};

			$scope.confirmFinishOrdersClick = function () {
				dialogSrv.confirmDialog(
					TranslationService.instant('DISPATCH_LIST.COMPLETE_ORDER_DIALOG_TITLE'),
					TranslationService.instant('DISPATCH_LIST.COMPLETE_ORDER_BODY'),
					'dispatchList.handleFinishOrdersClicked'
				);
			};

			$scope.handleFinishOrdersClicked = function () {
				if (!$scope.selectedItems.length) {
					toastr.error(TranslationService.instant('DISPATCH_LIST.TOAST_NO_ORDERS'));
					return;
				}

				Task.massFinish(
					{ task_ids: $scope.selectedItems.map(task => task.id) },
					function (result) {
						if (result.success) {
							toastr.success(TranslationService.instant('DISPATCH_LIST.SELECTED_ORDERS_FINISHED'));
						} else {
							toastr.error(TranslationService.instant('DISPATCH_LIST.FAILED_FINISHING_ORDERS'));
						}
					},
					function () {
						toastr.error(TranslationService.instant('DISPATCH_LIST.FAILED_FINISHING_ORDERS'));
					}
				);
			};

			function openOptimizationOrErrorModal(automaticOptimization, selectedTasks) {
				const optimizationType =
					optimizationTypesService.getConfiguredOptimizationType(selectedTasks[0].team_id) ||
					OPTIMIZATION_TYPES.ANONYMOUS;

				const missingLinkedTasks = LinkedTasksService.getMissingLinkedTasks(selectedTasks);
				if (missingLinkedTasks) {
					selectedTasks = [...missingLinkedTasks, ...selectedTasks];
				}
				const { errors, allowedToProceed, type } = OptimizationTasksValidationService.validate(selectedTasks);

				const invalidTasksIds = _.flatten(Object.values(errors).map(taskIds => Array.from(taskIds)));

				if (Object.keys(errors).length > 0) {
					const onProceedToOptimization = () =>
						$scope.openOptimizationModalWithValidTasks(
							optimizationType,
							automaticOptimization,
							selectedTasks,
							invalidTasksIds
						);

					ReactModals.openOptimizationErrorModal({
						errors,
						type,
						onProceedToOptimization: allowedToProceed && onProceedToOptimization,
						canProceedToOptimization: allowedToProceed && invalidTasksIds.length !== selectedTasks.length
					});
				} else {
					$scope.openOptimizationModal(optimizationType, automaticOptimization, selectedTasks);
				}
			}

			$scope.openOptimizationModalWithValidTasks = (
				type,
				automaticOptimization,
				selectedTasks,
				invalidTasksIds,
				isLinkedTasksAddedToOptimization
			) => {
				const validTasks = selectedTasks.filter(task => !invalidTasksIds.includes(task.id));
				$scope.openOptimizationModal(type, automaticOptimization, validTasks, isLinkedTasksAddedToOptimization);
			};

			$scope.openOptimizationModal = function (
				optimizationType,
				automaticOptimization,
				selectedTasks,
				invalidTasksIds
			) {
				let validTasks = selectedTasks;
				if (invalidTasksIds && invalidTasksIds.length) {
					validTasks = selectedTasks.filter(task => !invalidTasksIds.includes(task.id));
				}
				OptimizeModalService.open({
					dispatch: true,
					automaticOptimization,
					selectedTasks: validTasks,
					drivers: $scope.employees,
					type: optimizationType
				});
			};

			//==============================================
			// Route Optimizations
			//==============================================
			$scope.handleOptimizeSelectedClicked = function () {
				const optimizationType =
					optimizationTypesService.getConfiguredOptimizationType($scope.selectedItems[0].team_id) ||
					OPTIMIZATION_TYPES.ANONYMOUS;

				if (optimizationType === OPTIMIZATION_TYPES.COMBINED) {
					ReactModals.setIsOptimizationModalOpenerOpen({
						selectedTasks: $scope.selectedItems,
						isOptimizeNewAndSaveExisting: false
					});
				} else {
					openOptimizationOrErrorModal(false, $scope.selectedItems);
				}
			};

			//==============================================
			// Printing
			//==============================================

			$scope.handlePrintClicked = function () {
				var tasks = _.isEmpty($scope.selectedItems) ? TasksData.getOpenTasks() : $scope.selectedItems;
				DispatcherPrintService.handlePrintClicked(tasks);
			};

			$scope.handlePrintReceiptClicked = function () {
				var taskIds = $scope.selectedItems.map(task => task.id);
				Tasks.batchGet(taskIds).then(function (tasks) {
					PrintTasksService.printTasks(tasks);
				});
			};

			$scope.handlePrintLabelClicked = function (type) {
				const taskIds = $scope.selectedItems.map(({ id }) => id);

				CrossApplicationService.emit(CROSS_APP_ACTIONS.PRINT_LABEL_REQUEST, { taskIds, type });
			};

			//==============================================
			// Reschedule virtual tasks
			//==============================================

			$scope.handleRescheduleClicked = function () {
				TasksModalService.openScheduleTasksDialog($scope.selectedItems);
			};

			//==============================================
			// Broadcast tasks
			//==============================================

			$scope.handleBroadcastTasksClicked = function () {
				var teamIds = _.uniq($scope.selectedItems.map(task => task.team_id));
				if (teamIds.length === 1) {
					var taskIds = $scope.selectedItems.map(task => task.id);
					var drivers = _.filter($scope.employees, function (driver) {
						return driver.team_ids && driver.team_ids[0] === teamIds[0];
					});
					if (_.isEmpty(drivers)) {
						drivers = $scope.employees;
					}
					BroadcastTasksService.broadcastToDriversDialog(taskIds, drivers);
				} else {
					dialogSrv.notifyDialog(TranslationService.instant('BROADCAST_TASKS.NOTIFY_MESSAGE'));
				}
			};

			$scope.onDemandGrabModeEnabled = Tasks.onDemandGrabModeEnabled();

			//==============================================
			// Group Tasks
			//==============================================

			$scope.isSelectedContainsGroups = function () {
				return $scope.selectedItems.find(item => item.group_uuid);
			};

			$scope.handleUngroup = function () {
				if ($scope.isSelectedContainsGroups()) {
					Tasks.ungroupAll($scope.selectedItems);
				}
			};

			// Doesn't affect grid columns as they are managed by task-table.js gridColumns
			// Investigate if there is a reason to not pass gridColumn as a parameter to task-table
			const throttledRefreshData = _.throttle(function () {
				const newDataFunc = Task.shouldUseNewApi() && TasksData.getOpenTasks;
				const groupingType = $scope.groupingModel.selectedOption.value;
				$scope.grid.refreshData(newDataFunc, groupingType);
			}, DEFAULT_RT_THROTTLE);

			const throttledRefreshGrid = _.throttle(function () {
				$scope.grid.refresh();
			}, 2000);

			/**
			 * controller init function
			 */
			$scope._init = function () {
				var initPromises = [MerchantConfigurations.$refresh(), Authentication.featureFlags()];

				return $q.all(initPromises).then(function () {
					// force refresh when columns changed
					var openTasksOptions = {
						columnsKey: GRID_COLUMNS_KEY,
						grouping: SlickGridConfigService.getGrouping(GRID_DATA.DISPATCH.id, 'taskGridGroupMode')
					};

					TasksLoader.loadOpenForTable(openTasksOptions);
					var promises = [
						Employees.drivers(),
						VehiclesService.getAll(),
						VehicleTypesService.getAll(),
						UserType.getAll().catch(_.noop), // TODO please read comment below
						Tags.all(),
						PlannedRoutesService.getAll(),
						SkillsService.getAllSkills(),
						Application.isInstalledByUuid(Application.APPLICATIONS.RealtimeEta),
						DriverConfigurations.isClusterModeEnabled(),
						Application.getConfigByAppUuid(Application.APPLICATIONS.RealtimeEta).catch(_.noop)
					];
					/*
					 * TODO remove `catch` statement above via fixing business logic with idea in mind
					 *  that not every merchant has `user_types` feature or something
					 *  which causing `$q.all(promises)` below fall into catch and the whole page initiation went wrong by that
					 *  The fix `UserType.getAll().catch(function () {})` is temporary just to unblock customer.
					 * */

					if (MerchantConfigurations.enable_teams) {
						promises.push(Teams.all());
					}

					// The case CustomAttributesService.attributesEnabled is not covered by tests
					// featureFlags are needed for this
					if (CustomAttributesService.attributesEnabled) {
						promises.push(CustomAttributesService.getAll());
					}

					// The case PresetViewsService.getPresetViewsEnabled is not covered by tests
					// featureFlags are needed for this
					const presetViewsEnabled = PresetViewsService.getPresetViewsEnabled();
					if (presetViewsEnabled) {
						promises.push(PresetViewsService.getPresetsReadyPromise());
					}

					promises.push(ManagedAttributesService.loadAttributes());

					$scope.initGridColumns();

					return $q.all(promises).then(function (values) {
						$scope.employees = values[0];

						const vehicles = values[1];
						const vehicleTypes = values[2];
						$scope.userTypes = _.get(values[3], 'user_types');
						$scope.tags = values[4];
						$scope.plannedRoutes = values[5];
						SkillsService.setSkillsById(values[6]);
						_realtimeEtaEnabled = values[7];
						const isClusterModeEnabled = values[8];
						realTimeAppConfiguration = values[9];
						const teams = values[10];

						ServiceAreasService.loadAll().catch(function (e) {
							console.error('Could not load ServiceArea', e);
						});

						$scope.teams = teams;
						$scope.vehicleTypeByVehicleId = VehicleTypesService.getVehicleTypeByVehicleIds(
							vehicles,
							vehicleTypes
						);
						$scope.vehiclesById = VehiclesService.getVehiclesById(vehicles);
						$scope.vehicleTypes = vehicleTypes;
						Teams.setTeamHasTrailer($scope.teams, vehicles, $scope.vehicleTypeByVehicleId);

						$scope.isClusterModeEnabled = isClusterModeEnabled;
						$scope.userTypesByIds = _.keyBy($scope.userTypes, 'id');

						$scope._initGrouping();
						$scope.tasks = TasksData.getOpenTasks($scope.groupingModel.selectedOption.value);

						$scope.inited = true;
						calculateBulkAssignStatus();
					});
				});
			};

			var subscriptions = [];

			/**
			 * refresh task list
			 */

			$scope.onGridInited = function () {
				var handleRefresh = _.throttle(function () {
					// clear all tooltips if one has bean stuck due to slow page load
					CustomSlickgridTooltip.removeAll();
				}, DEFAULT_RT_THROTTLE);

				var handleRunChanges = function () {
					handleRefresh();
					if (
						$scope.groupingModel &&
						$scope.groupingModel.selectedOption &&
						($scope.groupingModel.selectedOption.value === TasksGrouping.GROUP_TYPES.TeamRoute ||
							$scope.groupingModel.selectedOption.value === TasksGrouping.GROUP_TYPES.Route)
					) {
						throttledRefreshData();
					}
				};

				if ($scope.grid.refreshFilters) {
					$scope.grid.refreshFilters();
				}

				Tags.all(handleRefresh);
				$rootScope.$on('employees list update', function () {
					Employees.drivers().then(function (drivers) {
						$scope.employees = drivers;
						throttledRefreshGrid();
					});
				});
				$scope.$on('task list update', handleRefresh);
				$scope.$on('lazy task list update', handleRefresh);
				$scope.$on('dispatchList.handleFinishOrdersClicked', $scope.handleFinishOrdersClicked);

				subscriptions.push(
					...[
						RunsService.onCreate(handleRunChanges),
						RunsService.onUpdate(handleRunChanges),
						RunsService.onDelete(handleRunChanges)
					]
				);
			};

			$scope.inited = false;

			// start init function
			$scope._init().then(function () {
				// Ensure that table has the latest columns after asynchronously added custom attribute columns
				// 'columns updated' seems to synchronise dispatch-controller with task-table.js
				if (
					CustomAttributesService.attributesEnabled ||
					PresetViewsService.enabledForConfigKey(GRID_COLUMNS_KEY)
				) {
					// _initGrouping needs to be called strictly after 'columns updated' handler
					// otherwise some selection issues may appear in grouping mode
					$scope.$broadcast('columns updated', function () {
						return $scope._initGrouping();
					});
				}
			});

			// comment to avoid any unexpected(opaque) UI flow when the PresetView is applied or data loaded the controller is fully rerendered
			CrossApplicationService.on(CROSS_APP_ACTIONS.PRESET_VIEWS_UPDATED, $scope._init);

			$scope.handleRoutesPlanner = function () {
				const partialRunIds = RunsService.getPartialRunIdsByTasks(
					$scope.selectedItems,
					TasksData.getOpenTasks()
				);
				let routesPlannerTasks = $scope.selectedItems;
				let isPartialRouteWasSelected = false;
				if (Object.keys(partialRunIds).length) {
					routesPlannerTasks = RunsService.getAllTasksOfRuns(TasksData.getOpenTasks(), partialRunIds);
					isPartialRouteWasSelected = true;
				}

				RoutesPlannerService.open(routesPlannerTasks, isPartialRouteWasSelected);
			};
		}
	);
