'use strict';
angular
	.module('bringgApp')
	.factory(
		'PrintTasksService',
		function (
			PrinterService,
			TaskInventoriesService,
			MerchantConfigurations,
			Teams,
			Tasks,
			CrossApplicationService,
			Authentication
		) {
			var TasksPrint = {};

			/**
			 * prepare task for print
			 */
			TasksPrint.prepareTaskForPrint = function (task) {
				task.way_point = _.last(task.way_points);
				task.way_point.task_inventories = TaskInventoriesService.getInventories(task, task.way_point);
				if (task.team_ids && task.team_ids.length) {
					task.team = Teams.fetch(task.team_ids[0]);
				}

				// copy task notes from one object to another
				task.way_point.notes = _.chain(task.task_notes)
					.filter({ way_point_id: task.way_point.id })
					.map(function (taskNote) {
						return taskNote.note;
					})
					.valueOf();

				return task;
			};

			Authentication.featureFlags().then(function (feature_flags) {
				TasksPrint.shouldUsePrintOrder = feature_flags && feature_flags.enable_print_order_and_conditionals;
			});

			/**
			 * print tasks collection
			 */
			TasksPrint.printTestTask = function (tasks, htmlTemplate) {
				if (!tasks) {
					return;
				}
				tasks = _.chain(tasks).map(_.clone).map(TasksPrint.prepareTaskForPrint).valueOf();

				//This would called on clicking the print button
				var printData = {
					tasks: tasks,
					config: { logo: MerchantConfigurations.logo, color: MerchantConfigurations.header_color }
				};
				PrinterService.printTestTask(printData, htmlTemplate);
			};

			/**
			 * print tasks collection
			 */
			TasksPrint.printTasks = async function (tasks) {
				if (!tasks) {
					return;
				}

				tasks = _.isArray(tasks) ? tasks : [tasks];

				if (TasksPrint.shouldUsePrintOrder) {
					tasks = await TasksPrint._mergeLinkedTasks(tasks);
				}

				tasks = _.chain(tasks).map(_.clone).map(TasksPrint.prepareTaskForPrint).valueOf();

				//This would called on clicking the print button
				var printData = {
					tasks: tasks,
					config: { logo: MerchantConfigurations.logo, color: MerchantConfigurations.header_color }
				};
				PrinterService.printTasks(printData);
			};

			TasksPrint._mergeLinkedTasksData = function (tasks) {
				const [pickupTask, dropoffTask] =
					tasks[0].task_type_id === 2 ? [tasks[0], tasks[1]] : [tasks[1], tasks[0]];

				pickupTask.way_points = [...pickupTask.way_points, ...dropoffTask.way_points];
				pickupTask.task_notes = [...pickupTask.task_notes, ...dropoffTask.task_notes];
				pickupTask.task_inventories = dropoffTask.task_inventories;

				return pickupTask;
			};

			TasksPrint._mergeLinkedTasks = async function (tasks) {
				const processedTaskIds = new Set();
				const processedTasks = [];

				for (const task of tasks) {
					if (processedTaskIds.has(task.id)) {
						continue;
					}

					processedTaskIds.add(task.id);

					// check if task has linked task
					if (!task.linked_task_id) {
						processedTasks.push(task);
						continue;
					}

					// Find linked task in provided tasks array
					let linkedTask = tasks.find(t => t.id === task.linked_task_id);

					// If linked task is not found in provided tasks array, fetch from database
					if (!linkedTask) {
						const taskArray = await Tasks.batchGet([task.linked_task_id]);

						if (!taskArray || !taskArray.length) {
							processedTasks.push(task);
							continue;
						}

						linkedTask = taskArray[0];
					}

					processedTaskIds.add(linkedTask.id);

					// merge waypoints
					const processedTask = TasksPrint._mergeLinkedTasksData([task, linkedTask]);

					processedTasks.push(processedTask);
				}

				return processedTasks;
			};

			TasksPrint._subscribeToPrintRequests = function () {
				CrossApplicationService.on('PRINT_TASKS_REQUEST', function (tasks) {
					TasksPrint.printTasks(tasks);
				});
			};

			TasksPrint._subscribeToTestPrintRequests = function () {
				CrossApplicationService.on('PRINT_TEST_TASKS_REQUEST', function (data) {
					TasksPrint.printTestTask(data.tasks, data.htmlTemplate);
				});
			};

			TasksPrint._subscribeToPrintRequests();
			TasksPrint._subscribeToTestPrintRequests();

			return TasksPrint;
		}
	);
