'use strict';

angular
	.module('bringgApp.directives')
	.directive(
		'signedInVer2',
		function (Authentication, ClientStatsService, SubmitTicketService, $timeout, $rootScope, PRIVILEGES_TYPES) {
			return {
				templateUrl: 'scripts/new_navigation/signed_in_ver2/signed-in-ver2.html',
				scope: {
					currentUser: '=',
					merchant: '='
				},
				link: function (scope, element, attrs) {
					scope.canViewProfile = false;

					scope.signOutClicked = function () {
						Authentication.logout();
					};

					scope.submitTicket = function () {
						SubmitTicketService.open();
					};

					scope.toggled = open => {
						scope.menuOpened = open;

						if (open) {
							const dropdown = element.find('.dropdown-menu');
							dropdown.css({
								top: -dropdown.height() - 5
							});
						}
					};

					scope.handleMerchantInfoClicked = function () {
						ClientStatsService.downloadLogs();
					};

					scope.toggleMenuCollapse = () => {
						$rootScope.topClass = $rootScope.topClass === 'collapsed' ? null : 'collapsed';
					};

					$timeout(() => {
						const userNameElem = element.find('.user-name')[0];
						scope.isUserNameEllipsisActive = userNameElem.scrollWidth > userNameElem.clientWidth;
					});

					scope.unwatchIsLoggedIn = scope.$watch(Authentication.isLoggedIn, function (isLoggedIn) {
						if (isLoggedIn) {
							scope.canViewProfile = getCanViewProfile();
						}
					});

					function getCanViewProfile() {
						const user = Authentication.currentUser();
						const hasFeatureFlag = user.feature_flags.dispatcher_new_side_menu_items;

						if (!hasFeatureFlag) {
							// by old flow button available always, no check required
							return true;
						}

						if (user.admin) {
							return true;
						}

						const requiredPrivileges = [
							[PRIVILEGES_TYPES.DISPATCHER_PROFILE, PRIVILEGES_TYPES.DISPATCHER_PROFILE_EDIT],
							[PRIVILEGES_TYPES.DISPATCHER_PROFILE, PRIVILEGES_TYPES.DISPATCHER_PROFILE_VIEW]
						];

						return requiredPrivileges.some(([priv, sub]) => Authentication.userHasAccess(priv, sub));
					}

					scope.$on('$destroy', function () {
						unwatchIsLoggedIn();
					});
				}
			};
		}
	);
