'use strict';

angular.module('bringgApp').factory('TaskDetailsService', function ($q, Employees, VehiclesService, Fleets) {
	var TaskDetailsService = {};

	TaskDetailsService.fetchFleetName = function (task) {
		if (!_.isNumber(task.fleet_id)) {
			return;
		}
		task.fleetName = Fleets.getName(task.fleet_id);
	};

	TaskDetailsService._onUserLoaded = function (user, companies) {
		if (!_.isNumber(user.company_id)) {
			return;
		}
		if (!_.isNil(user.company)) {
			return;
		}
		user.company = _.find(companies, { id: user.company_id });
	};

	TaskDetailsService._fetchTaskUser = function (task, companies) {
		if (!_.isNil(task.user)) {
			TaskDetailsService._onUserLoaded(task.user, companies);
			return $q.resolve();
		}

		if (!_.isNumber(task.user_id)) {
			return $q.resolve();
		}

		return Employees.get({ id: task.user_id }).then(function (user) {
			task.user = user;
			TaskDetailsService._onUserLoaded(task.user, companies);
		});
	};
	TaskDetailsService._fetchVehicleDetails = function (task) {
		if (task.vehicle_id && !task.vehicle) {
			return VehiclesService.getVehicleDetailsByVehicleId(task.vehicle_id).then(
				function (vehicle) {
					task.vehicle = vehicle;
				},
				function (err) {
					console.error(err);
				}
			);
		}
		return $q.resolve();
	};
	TaskDetailsService.fetchTaskData = function (task, companies) {
		return $q.all([
			TaskDetailsService._fetchTaskUser(task, companies),
			TaskDetailsService._fetchVehicleDetails(task)
		]);
	};

	return TaskDetailsService;
});
