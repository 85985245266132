'use strict';

angular.module('bringgApp').controller(
	'MerchantController',
	function (
		$scope,
		$http,
		MerchantConfigurationService,
		DriverConfigurations,
		$location,
		$rootScope,
		SocketPubSub,
		Authentication,
		MerchantService,
		Token,
		dialogSrv,
		toastr,
		ENV_CONFIGURATION,
		Employee,
		Merchant,
		$window,
		BecomeDeveloperService,
		BetaProgramService,
		MerchantConfigurations,
		localStorageService,
		PRIVILEGES_TYPES,
		SUBSCRIPTION_TYPE,
		WindowCommunicationService,
		$log,
		HOUR_FORMAT,
		$q,
		$translate,
		$sce,
		// PrintTasksService needs to be initialized to _subscribeToPrintRequests and _subscribeToTestPrintRequests
		PrintTasksService
	) {
		$scope.data = {};

		//==============================================
		// enable/disable console logging
		//==============================================
		$scope.data.enableLogging = localStorageService.get('logEnabled');
		$scope.$watch('data.enableLogging', function (newValue, oldValue) {
			if (newValue === oldValue) {
				return;
			}

			localStorageService.set('logEnabled', newValue);
			$log.enable(newValue);
		});

		$scope.userConfirmed = false;
		$scope.allowBecomeDeveloper = true;
		$scope.merchantModeValues = [
			'Default',
			'Single Pickup Location, Multiple Dropoff Locations',
			'Multiple Pickup Locations, Multiple Dropoff Locations',
			'Route'
		];
		$scope.selectedMerchantType = MerchantConfigurationService.merchant_mode;
		$scope.readonly = Authentication.currentUser().partner_user;
		$scope.isMerchantLogsAllowed = Authentication.currentUser().has_access(PRIVILEGES_TYPES.ENABLE_MERCHANT_LOGS);
		$scope.isPageReady = false;

		$scope.sendCredentials = function () {
			WindowCommunicationService.sendCredentials();
		};

		$scope.$watch(Authentication.isLoggedIn, function (isLoggedIn) {
			if (isLoggedIn) {
				$scope.current_user = Authentication.currentUser();
				$scope.enableDeliveryBlocksTemplates =
					$scope.current_user.feature_flags.enable_delivery_blocks_templates;
				$scope.enableOptimizationsMenu = $scope.current_user.feature_flags.enable_optimizations_menu;
				$scope.enableConfigurationsAuditPage = $scope.current_user.admin;
				$scope.show_optimization_settings_page =
					$scope.current_user.feature_flags.show_optimization_settings_page;

				$scope.userConfirmed = !!$scope.current_user.confirmed_at;
				MerchantConfigurationService.get().then(function (data) {
					$scope.merchantConfiguration = data;
					$scope.merchantConfiguration.hour_format =
						$scope.merchantConfiguration.hour_format || HOUR_FORMAT.Default;
					$scope.showFleetsConfigurationMenu = !$scope.merchantConfiguration.delivery_hub_main_menu_ui;
				});
				MerchantService.get().then(function (data) {
					$scope.isPageReady = true;
					$scope.merchant = data;
					$scope._originalMerchant = angular.copy(data);
					if (data.test_env || data.production_env) {
						$scope.data.switchAccounts = $scope.merchant.production_env;
						$scope.$watch('data.switchAccounts', function (newValue, oldValue) {
							if (newValue === oldValue) {
								return;
							}
							Authentication.switch_accounts();
						});
					}

					if (!$scope.current_user.has_access('enable_setting_page') && !$scope.current_user.admin) {
						$location.path($location.$$path - 1);
					}
				});
			}
		});

		$scope.$on('currentUserUpdated', function (event, user) {
			var confirmed = !!user.confirmed_at;
			if ($scope.userConfirmed != confirmed) {
				$scope.userConfirmed = confirmed;
				$scope.$apply();
			}
		});

		$scope.updateMerchant = function () {
			/*
			 * By https://bringg.atlassian.net/browse/BRNGG-25210
			 * If Technical contact is changed - the same value must be populated as Alternative Feedback Email.
			 * This is done from UI (instead of DB Model) to leave ability change only technical contact in BControl.
			 * This is a side operation that's why it is not included to $q.all array and done after merchant
			 * settings successful update.
			 * */
			const shouldUpdateAlternativeFeedbackEmail =
				$scope.merchant.technical_contact !== $scope._originalMerchant.technical_contact;

			$q.all([
				Merchant.update($scope.merchant),
				MerchantConfigurationService.update($scope.merchantConfiguration)
			]).then(
				function (results) {
					var merchantResult = results[0],
						merchantConfigurationResult = results[1];

					if (merchantResult && merchantConfigurationResult) {
						toastr.success($translate.instant('MERCHANT.UPDATE_SAVED'));
						$scope.merchant = merchantResult;
						$scope._originalMerchant = angular.copy(merchantResult);
						$scope.merchantConfiguration = merchantConfigurationResult;
					} else {
						toastr.error($translate.instant('MERCHANT.UPDATE_FAILED'));
					}

					if (shouldUpdateAlternativeFeedbackEmail) {
						DriverConfigurations.get().then(function (driverConfiguration) {
							DriverConfigurations.update(
								Object.assign(driverConfiguration, {
									alternative_feedback_email_address: merchantResult.technical_contact
								})
							);
						});
					}
				},
				function () {
					toastr.error($translate.instant('MERCHANT.UPDATE_FAILED'));
				}
			);
		};

		$scope.becomeDeveloperClicked = false;
		$scope.getDeveloperKey = function () {
			$scope.becomeDeveloperClicked = true;

			if ($scope.merchant.developer_account) {
				$location.path('/merchant/developer/');
			} else if ($scope.userConfirmed) {
				BecomeDeveloperService.open().then(
					function (result) {
						Token.createDeveloperTokens(function (data) {
							if (data.success) {
								$location.path('/merchant/developer/');
								//alert('Production:\naccess token: ' + data.data.auth_token + '\nsecret key: ' + data.data.hmac_secret + '\nTest:\naccess token: ' + data.data.test_auth_token + '\nsecret key: ' + data.data.test_hmac_secret);
							} else {
								if (data.message === 'email_not_verified') {
									$scope._showNotConfirmedAccountAlert();
									$scope.becomeDeveloperClicked = false;
								} else {
									toastr.error('Failed getting developer key');
									$scope.becomeDeveloperClicked = false;
								}
							}
						});
					},
					function (reason) {
						$scope.becomeDeveloperClicked = false;
					}
				);
			} else {
				$scope._showNotConfirmedAccountAlert();
				$scope.becomeDeveloperClicked = false;
			}
		};

		$scope._showNotConfirmedAccountAlert = function () {
			dialogSrv.richHtmlDialog(
				'Thank you for applying to become a Bringg Developer!',
				'<p style="line-height: 28px">You are one step away from becoming a <b>Bringg Developer</b>. ' +
					'<br/><u>Please verify your email address to get started.</u>' +
					'<br/><br/>Once verified, please resubmit your request for API access.</p>'
			);
		};

		$scope.handleResendConfirmationClick = function () {
			Employee.resend_confirmation_instructions(function (responce) {
				if (responce.success) {
					toastr.success('New email with confirmation link was sent.');
				} else {
					toastr.error('Error sending a confirmation email.');
				}
			});
		};

		$scope.handleAddressSearch = function (search, refreshAddresses) {
			return refreshAddresses(search);
		};

		$scope.isAccountManager = function () {
			return $scope.current_user.beta && $scope.current_user.admin && $scope.current_user.merchant_id === 1;
			//return true;
		};

		//==============================================
		// Change merchant settings tabs
		//==============================================
		$scope._tabs = [
			{ name: 'tab1', path: '/merchant/' },
			{ name: 'tab1', path: '/merchant/settings/' },
			{ name: 'tab2', path: '/merchant/users/' },
			{ name: 'tab3', path: '/merchant/customer-app/' },
			{ name: 'tab4', path: '/merchant/driver-app/' },
			{ name: 'tab5', path: '/merchant/billing/' },
			{ name: 'tab6', path: '/merchant/merchant-config/' },
			{ name: 'tab7', path: '/merchant/webhooks/' },
			{ name: 'tab8', path: '/merchant/developer/' },
			{ name: 'tab9', path: '/merchant/applications/' },
			{ name: 'tab10', path: '/merchant/setup/' },
			{ name: 'tab11', path: '/merchant/notifications/' },
			{ name: 'tab13', path: '/merchant/customer-notifications/' },
			{ name: 'tabAccountManager', path: '/merchant/account-manager/' },
			{ name: 'tabPayments', path: '/merchant/payments/' },
			{ name: 'tags', path: '/merchant/tags/' },
			{ name: 'logs', path: '/merchant/logs/' },
			{ name: 'store-app', path: '/merchant/store-app/' },
			{ name: 'fleets-configuration', path: '/merchant/fleets-configuration/' },
			{ name: 'delivery-catalog-connect', path: '/delivery-catalog-connect' },
			{ name: 'fleets-rule-list', path: '/fleets-rule-list' },
			{ name: 'delivery-catalog', path: '/delivery-catalog' },
			{ name: 'configurations-audit', path: '/merchant/configurations-audit/' },
			{ name: 'optimization-configuration', path: '/merchant/optimization-configuration/' },
			{ name: 'automationWorkflow', path: '/merchant/automations/' },
			{ name: 'automationWorkflow/workflow', path: '/merchant/automations/:id' },
			{ name: 'serviceAreas', path: '/merchant/service-areas' },
			{ name: 'serviceAreas/traffic/serviceArea', path: '/merchant/service-areas/traffic/:id' },
			{ name: 'deliveryBlocks', path: '/merchant/delivery-blocks-templates/' },
			{ name: 'delivery-blocks-new-template', path: '/merchant/delivery-blocks-templates/new' },
			{ name: 'delivery-blocks-edit-template', path: '/merchant/delivery-blocks-templates/:id' }
		];

		$scope._setCurrentTab = function (path) {
			$scope.view_tab = _.find($scope._tabs, function (item) {
				// remove the param from  item.path
				const _path = item.path.split('/:')[0];

				if (_path === path) {
					// if a match, no param in path
					return true;
				}

				// if path with param, remove it
				const pathWithoutParams = path.split('/').slice(0, -1).join('/');
				return _path === pathWithoutParams;
			}).name;
		};

		$scope._setCurrentTab($location.path());

		$scope.changeTab = function (tab) {
			const tabPath = _.find($scope._tabs, function (item) {
				return item.name === tab;
			}).path;

			// Remove the query params between tab
			$location.url(tabPath);
		};
	}
);
