'use strict';

angular.module('bringgApp').constant('PRIVILEGES_TYPES', {
	DISPATCH_SCREEN: 'dispatch',
	CREATE_ORDERS: 'create_order',
	VIEW_TASK: 'view_task',
	ACKNOWLEDGE_FLOW: 'acknowledge',
	HISTORY: 'history',
	DRIVERS: 'drivers',
	DRIVER_PRORFILE: 'driver_profile',
	CHANGE_DRIVER_SKILLS: 'change_driver_skills',
	CREATE_DRIVER_SKILLS: 'create_driver_skills',
	ADD_DRIVER: 'add_driver',
	CHANGE_DRIVER_ROLE: 'change_driver_type',
	ASSIGN_DRIVERS_TO_TASK: 'assign_driver_to_task',
	TEAMS: 'teams',
	USER_TYPES: 'user_types',
	CUSTOMERS: 'customers_menu',
	REPORTS: 'reports',
	CASHOUT_EDIT_DISTANCE: 'cashout_edit_distance',
	ACCESS_SETTINGS_SCREEN: 'access_settings_screen',
	CUSTOMER_CARE: 'customer_care',
	MAINTAIN_DRIVER_SHIFT_TIMES: 'maintain_driver_shift_times',
	COMPLETE_TASK: 'complete_task',
	EDIT_DELIVERY_BLOCK: 'edit_delivery_block',
	CANCEL_TASK: 'cancel_task',
	SEND_TO_DRIVERS: 'send_to_drivers',
	OPTIMIZE_ORDERS: 'optimize_orders',
	LOCK_UNLOCK_TASK: 'lock_unlock_task',
	RESCHEDULE_CANCELLED_ORDER: 'reschedule_cancelled_order',
	EDIT_TASK_DETAILS: 'edit_task_details',
	ACCESS_USERS: 'access_user',
	ACCESS_COMPANY_USERS_ONLY: 'access_company_users_only',
	ASSIGN_USER_TO_COMPANY: 'assign_user_to_company',
	ENABLE_WEBHOOKS: 'enable_webhooks',
	ENABLE_MERCHANT_LOGS: 'enable_merchant_logs',
	ENABLE_SETTING_PAGE: 'enable_setting_page',
	ENABLE_FLEET_ROUTER: 'enable_fleet_router',
	BILLING_PORTAL: 'billing_portal',
	CUSTOMIZE_LIST_LAYOUT: 'customize_list_layout',
	ALLOW_ASSIGN_VEHICLE_TO_RUN: 'allow_assign_vehicle_to_run',
	REASSIGN_FLEET: 'reassign_fleet',
	ENABLE_SELF_ASSIGNMENT_TO_TEAMS: 'enable_self_assignment_to_teams',
	MODIFY_PLANNED_ROUTE_IDENTIFIER: 'modify_planned_route_identifier',
	ALLOW_USING_ROUTE_PLANNER_IN_DISPATCH: 'allow_using_route_planner_in_dispatch',
	ALLOW_USING_ROUTE_PLANNER_IN_PLANNING: 'allow_using_route_planner_in_planning',
	VIEW_PLANNED_DELIVERY_WINDOWS: 'view_planned_delivery_windows',
	EDIT_PLANNED_DELIVERY_WINDOWS: 'edit_planned_delivery_windows',
	DRIVER_PROFILE_V2: 'driver_profile_v2',
	DRIVER_PROFILE_V2_EDIT: 'edit',
	DRIVER_PROFILE_V2_VIEW: 'view',
	DRIVER_PROFILE_V2_NONE: 'none',
	TEAMS_V2: 'teams_v2',
	TEAMS_V2_EDIT: 'edit',
	TEAMS_V2_VIEW: 'view',
	TEAMS_V2_NONE: 'none',
	ADD_TEAM: 'add_team',
	DISPATCHER_PROFILE: 'dispatcher_profile',
	DISPATCHER_PROFILE_EDIT: 'edit',
	DISPATCHER_PROFILE_VIEW: 'view',
	DISPATCHER_PROFILE_NONE: 'none',
	ALLOW_DISPATCHERS_TO_ADD_DISPATCHER: 'allow_dispatchers_to_add_dispatcher',
	SEND_TASK_FROM_DISPATCH_TO_PLANNING: 'send_task_from_dispatch_to_planning'
});
